import React, { Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import PageHeading from '../ui/PageHeading/PageHeading';
import { useLazyQuery, useQuery } from '@apollo/client';
import ProfileLoading from '../ui/ProfileLoading/ProfileLoading';
import { Card } from '@nextui-org/react';
import InstitutionChip from '../ui/InstitutionChip/InstitutionChip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { MID_GREY } from '../../utils/colours';
import GET_LIST from './queries/list';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../store/slices/uiSlice';
import TopicNetworkDiagram from '../TopicProfile/TopicNetworkDiagram/TopicNetworkDiagram';
import LIST_TIMELINE from './queries/listTimeline';
import Timeline from '../Timeline/Timeline';
import FirmCard from '../FirmCard/FirmCard';
import Chip from '../ui/Chip/Chip';

const ListProfile = ({

}) => {

    const { list } = useParams();
    const dispatch = useDispatch();

    const { loading, error, data } = useQuery(GET_LIST, {
        variables: {
            list: list
        }
    });

    const [loadTimeline, { loading: loadingTimeline, error: timelineError, data: timelineData, fetchMore }] = useLazyQuery(LIST_TIMELINE)

    useEffect(() => {
        if (data) {
            dispatch(setBreadcrumb(data.list.name));
            const companyNames = data.list.companies.map(firm => firm.name);
            loadTimeline({
                variables: {
                    companies: companyNames,
                    offset: 0,
                    limit: 20
                }
            });
        }
    }, [data]);

    const onFetchMore = () => {
        fetchMore({
            variables: {
                offset: timelineData.timeline.length
            }
        })
    }

    if (loading && !data) {
        return (
            <Fragment>
                <ProfileLoading />
            </Fragment>
        )
    }     

    const sortedCompanies = [...data.list.companies].sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    })

    const companyChips = sortedCompanies.map((firm, i) => {
        return (
            <Link to={`/company/?name=${firm.name}`}>
                <div className='px-2 py-1 rounded-lg shadow inline-block text-sm mr-1 mb-1 hover:bg-offwhite'>
                    {firm.name}
                </div>
            </Link>
        )
    }).sort((a, b) => a.name - b.name);

    const firmCards = data.list.companies.map((firm, i) => {
        return (
            <FirmCard 
                key={i} 
                officialCount={false}
                {...firm} 
            />
        )
    })

    const subjectBlocks = data.list.companies.map((firm, i) => {

        const subjects = firm.subjects.map((subject,i) => {
            return (
                <p key={i} className='mb-3 pb-4 border-b border-gray-200 last:mb-0 last:border-none text-lg'>
                    {subject.subject}
                </p>
            )
        });

        const institutionTags = firm.institutions.filter((inst, i) => i < 3).map((inst, i) => {
            return <InstitutionChip institution={inst.name} key={i}  />
        })

        return (
            <Link to={`/company/?name=${firm.name}`} 
                key={i}     
            >
                <Card 
                    className='px-7 py-4 my-3 hover:bg-offwhite transition-all font-normal' 
                    shadow='sm'
                >
                    <div className='flex items-center gap-2 justify-between'>
                        <span className='font-bold'>
                            {firm.name}
                        </span>
                        <div>
                            <div className='flex gap-0.5 items-center'>
                                {/* <div className='flex gap-0.5'> */}
                                    {institutionTags}
                                {/* </div> */}
                                {firm.institutions.length > 3 && (
                                    <Chip label={`+${firm.institutions.length - 3}`} />
                                )}
                            </div>
                        </div>
                    </div>
           
                    <div className=''>
                        {subjects}
                    </div>
                    <div>
                        <p className='text-xs font-medium text-dark-grey'>
                            {firm.meetingCount} meetings with Canadian government officials in the past year
                        </p>
                    </div>    
                </Card>
            </Link> 
        )
    })

    return (
        <div className='mb-24'>
            <PageHeading text={data.list.name} />
            <div className='border-t border-gray-200 mt-6 pt-2'>
                <p className='text-dark-grey font-bold text-sm mb-2'>
                    Tracking {data.list.companies.length} organizations
                </p>
                <div className=''>
                    {companyChips}
                </div>
            </div>
            <div>
                <TopicNetworkDiagram
                    data={data.list.network}
                />
            </div>
            <div className='mt-8 grid grid-cols-3 gap-2'>
                {firmCards}
            </div>
            <div className='mt-12'>
                <p className='text-lg font-bold mb-4'>Meetings with public office holders</p>
                {timelineData && (
                    <Timeline
                        timeline={timelineData.timeline} 
                        totalTimeline={timelineData.totalTimeline}
                        template="PUBLIC_SERVANT"
                        showCompany
                        onFetchMore={onFetchMore}
                    />
                )}
            </div> 
        </div>
    )
}

export default ListProfile;