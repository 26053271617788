import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { getInstitutionName } from '../../ui/InstitutionName/InstitutionName';
import { getInstitutionAcronym } from '../../ui/InstitutionChip/InstitutionChip';
import { escapeSelector } from '../../../utils/formatters';
import ChartTooltip from '../../charts/ChartTooltip/ChartTooltip';
import StackedBarTooltipContent from '../../charts/StackedBarChart/StackedBarTooltipContent/StackedBarTooltipContent';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndustry, faInstitution } from '@fortawesome/free-solid-svg-icons';
import { MID_GREY } from '../../../utils/colours';

function calculateNodeImportance(network) {
    const importance = {};

    // Initialize importance for each node
    network.nodes.forEach(node => {
        importance[node.id] = 0;
    });

    // Calculate importance based on degree and link weights
    network.links.forEach(link => {
        const source = link.source;
        const target = link.target;
        const weight = link.value || 1;

        // Add weighted importance
        importance[source] += weight;
        importance[target] += weight;
    });

    return importance;
}

const TopicNetworkDiagram = ({
    data = { nodes: [], links: [] }
}) => {
    const svgRef = useRef(null);

    const [tooltipNode, setTooltipNode] = useState({ 
        name: '',
        type: 'company',
        visible: false
    });
    const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 });

    const navigate = useNavigate();

    // const filteredNetwork = filterTopNodes(data, 20);
    const filteredNodeImportance = calculateNodeImportance(data);
    const filteredNodeImportanceArray = Object.keys(filteredNodeImportance).map((key) => {
        return {
            id: key,
            value: filteredNodeImportance[key]
        }
    }).sort((a, b) => b.value - a.value)
    const maxWeight = Math.max(...data.links.map(link => link.weight));

    useEffect(() => {
        if (!svgRef.current || !data.nodes.length) return;

        const container = document.getElementById('NetworkContainer');

        // Create fully mutable copies of nodes with all potential D3 properties
        const nodes = data.nodes.map(node => ({
            ...node,
            // Add properties that D3 force simulation will modify
            vx: 0,
            vy: 0,
            x: undefined,
            y: undefined,
            fx: null,
            fy: null,
            index: undefined
        }));

        // Create a map for quick node lookup
        const nodeMap = new Map(nodes.map(node => [node.id, node]));

        // Create fully mutable copies of links with resolved references
        const links = data.links.map(link => ({
            ...link,
            // Resolve source and target to actual node objects
            source: nodeMap.get(link.source) || link.source,
            target: nodeMap.get(link.target) || link.target,
            // Add any other properties D3 might need
            index: undefined
        }));

        // Clear any existing content
        d3.select(svgRef.current).selectAll("*").remove();

        // Set up the SVG
        const width = 800;
        const height = 500;
        const svg = d3.select(svgRef.current)
            .attr("viewBox", [0, 0, width, height])
            .attr("class", "bg-white rounded-2xl");

        // Color scale for different node categories
        const colorScale = d3.scaleOrdinal()
            .domain(["client", "registrant", "official", "institution"])
            .range(["#93927a", "#34d399", "#f87171", "#f87171"]);

        // Create the force simulation
        const simulation = d3.forceSimulation()
            .nodes(nodes)
            .force("link", d3.forceLink(links)
                .id(d => d.id)
                .distance(150))
            .force("charge", d3.forceManyBody().strength(d => {
                return -1500
            }))
            // .force("center", d3.forceCenter(width / 2, height / 2))
            .force("collision", d3.forceCollide().radius(35))
            .force("x", d3.forceX(width / 2).strength(0.3))
            .force("y", d3.forceY(height / 2).strength(0.55));  // Lower strength vertically

        let tooltipTimeout;

        // Create the links
        const link = svg.append("g")
            .selectAll("line")
            .data(links)
            .join("line")
            .attr("stroke", "#e0ddda")
            .attr("stroke-opacity", d => {
                const rawOpacity = d.weight * 2 / maxWeight;
                const adjustedOpacity = rawOpacity < 0.2 ? 0.3 : rawOpacity;
                return adjustedOpacity;
            })
            .attr("stroke-width", d => Math.sqrt(d.weight))
            // .attr("class", "opacity-0");

        // Create the nodes
        const node = svg.append("g")
            .selectAll("g")
            .data(nodes)
            .join("g")
            // .style("opacity", 0)  // Initially hidden
            .on('mouseover', (event, d) => {

                // if (windowWidth <= 640) {
                //   return;
                // }

                console.log('mouse over');
        
                // Get the container's position relative to the viewport
                const containerRect = container.getBoundingClientRect();
                
                // // Calculate the mouse position relative to the container
                const x = event.clientX - containerRect.left;
                const y = event.clientY - containerRect.top;
                console.log('x, y', x, y);
                setTooltipNode({
                    name: d.id,
                    type: d.category,
                    visible: true
                });
                setTooltipPos({ x: x, y: y });
                
              })
              .on('mousemove', (event) => {
      
                // if (windowWidth <= 640) {
                //   return;
                // }
                // Get the container's position relative to the viewport
                const containerRect = container.getBoundingClientRect();
                
                // Calculate the mouse position relative to the container
                const x = event.clientX - containerRect.left;
                const y = event.clientY - containerRect.top;
                clearTimeout(tooltipTimeout);
                setTooltipPos({ x: x, y: y });
              })
              .on('click', (event, d) => {
                navigate(`/${d.category}/?name=${d.id}`)
              })
              .on('mouseout', (event, d) => {
      
                // if (windowWidth <= 640) {
                //   return;
                // }
      
                // const nodeObj = getNodeWithinD3EventHandler(event, d, profileType, false);
      
                 // Set a timeout to hide the tooltip after a brief delay
                tooltipTimeout = setTimeout(() => {
                  setTooltipNode({
                    name: d.id,
                    type: d.category,
                    visible: false
                  });
                }, 100);
              })

        // Add circles to nodes
        node.append("circle")
            .attr("r", d => Math.sqrt(filteredNodeImportance[d.id]) * 3)
            .attr("fill", d => colorScale(d.category))
            .attr("class", "cursor-pointer")

        // Add labels to nodes
        node.append("text")
            .attr("x", d => {
                // Default to the right side of the node
                const labelOffset = Math.sqrt(filteredNodeImportance[d.id]) * 3 + 4;
                return (d.x + labelOffset > width - 20) ? -labelOffset : labelOffset;
            })
            .attr("y", 4)
            .attr("font-size", 10)
            .attr("data-id", d => d.id)
            .text(d => {
                let label = '';
                // const randomInt = randomIntFromInterval(1, 3);
                // if (randomInt === 1) {
                //     label = d.id;
                // }
                // if (d.category === 'institution') {
                //     label = getInstitutionAcronym(d.id)
                // }
                if (filteredNodeImportanceArray.findIndex(node => node.id === d.id) < 15) {
                    label = d.id;
                    if (d.category === 'institution') {
                        label = getInstitutionAcronym(d.id);
                    }
                }
                return label;
            })
            // .attr("fill-opacity", 0)  // Initially hidden
            .attr("class", "font-bold fill-gray-700");
            

        // Add drag behavior
        const drag = d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);

        node.call(drag);

        // === Repel Labels Function ===
        function repelLabels(alpha) {
            const padding = 40;

            node.selectAll("text").each(function (d) {
                const bbox = this.getBBox();

                nodes.forEach(other => {
                    if (other !== d) {
                        const otherLabel = d3.select(`text[data-id='${escapeSelector(other.id)}']`);
                        const otherBBox = otherLabel.node()?.getBBox();  // Use optional chaining to avoid errors

                        const dx = d.x - other.x;
                        const dy = d.y - other.y;
                        const distance = Math.sqrt(dx * dx + dy * dy);

                        const overlapX = d.x < other.x + otherBBox.width + padding &&
                            d.x + bbox.width + padding > other.x;
                        const overlapY = d.y < other.y + otherBBox.height + padding &&
                            d.y + bbox.height + padding > other.y;

                        if (overlapX && overlapY) {
                            const overlap = Math.max(1, distance);
                            const shiftX = dx / overlap * alpha * 0.5;
                            const shiftY = dy / overlap * alpha * 0.5;

                            d.x += shiftX;
                            d.y += shiftY;
                            other.x -= shiftX;
                            other.y -= shiftY;
                        }
                    }
                });
            });
        }

        simulation.tick(500);
        simulation.alphaDecay(0.035);
        simulation.alpha(0.5).restart();

        // Update positions on each tick
        simulation.on("tick", () => {
            // Constrain nodes to SVG bounds
            nodes.forEach(node => {
                node.x = Math.max(20, Math.min(width - 20, node.x));
                node.y = Math.max(20, Math.min(height - 20, node.y));
            });

            repelLabels(2);

            // Adjust label positioning based on node position
            node.selectAll("text").each(function (d) {
                const bbox = this.getBBox();  // Get bounding box of the text element
                const textWidth = bbox.width;
                const labelOffset = Math.sqrt(filteredNodeImportance[d.id]) * 3 + 4;

                d3.select(this).attr("x", d.x + labelOffset + bbox.width > width - 20 ? -labelOffset - textWidth : labelOffset);
            });

            link
                .attr("x1", d => d.source.x)
                .attr("y1", d => d.source.y)
                .attr("x2", d => d.target.x)
                .attr("y2", d => d.target.y);

            node
                .attr("transform", d => `translate(${d.x},${d.y})`);

        });

        // Drag functions
        function dragstarted(event) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            event.subject.fx = event.subject.x;
            event.subject.fy = event.subject.y;
        }

        function dragged(event) {
            event.subject.fx = event.x;
            event.subject.fy = event.y;
        }

        function dragended(event) {
            if (!event.active) simulation.alphaTarget(0);
            event.subject.fx = null;
            event.subject.fy = null;
        }

        // Cleanup function
        return () => {
            simulation.stop();
        };

    }, [data]); // Re-run effect when data changes

    // If no data is provided, show a placeholder
    if (!data.nodes.length) {
        return (
            <div className="w-full max-w-4xl mx-auto p-4 text-center text-gray-500">
                No network data available
            </div>
        );
    }

    return (
        <div 
            className="w-full mt-4 relative rounded-2xl"
            id='NetworkContainer'
        >
            <svg ref={svgRef} className="w-full aspect-[4/3]" />
            <ChartTooltip
                node={tooltipNode} 
                position={tooltipPos}
                visible={tooltipNode.visible}
            >
                <div className='flex gap-3 items-start'>
                    <FontAwesomeIcon 
                        icon={tooltipNode.type === 'company' ? faIndustry : faInstitution} 
                        color={MID_GREY}
                        className='relative top-[2px]'
                        size='sm'
                    />
                    <span className='font-bold text-sm leading-snug'>
                        {tooltipNode.type === 'company' ? tooltipNode.name : getInstitutionName(tooltipNode.name)}
                    </span>
                </div>
            </ChartTooltip>
        </div>
    );
};

export default TopicNetworkDiagram;