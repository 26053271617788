import React from 'react';

const Chip = ({
    label
}) => {

    return (
        <span className='text-gray-800 font-medium bg-mid-grey text-white rounded-xl text-xs tracking-tight px-2 pt-1 py-1 leading-none overflow-ellipsis whitespace-nowrap inline-block truncate ellipsis max-w-24'>
            {label}
        </span>
    )
}

export default Chip;