import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Card } from '@nextui-org/react';
import { Link } from 'react-router-dom';
import InstitutionChip from '../ui/InstitutionChip/InstitutionChip';
import Chip from '../ui/Chip/Chip';

const FirmCard = ({
    name,
    meetingCount,
    contactCount,
    institutions,
    subjects,
    fixedHeight = true,
    officialCount = true
}) => {

    const containerRef = useRef(null);
    const [isContentTaller, setIsContentTaller] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            setIsContentTaller(container.scrollHeight > 100);
        }
    }, []);

    const sortedSubjects = [...subjects].sort((a, b) => {
        return new Date(b.start) - new Date(a.start);
    })

    const subjectBullets = sortedSubjects.map((s, i) => {
        return <li key={i} className='mb-2 leading-snug font-normal'>{s.subject}</li>
    })

    const instChips = institutions.filter((i, n) => n < 3).map((institution, n) => {
        return <InstitutionChip institution={institution.name} key={n} />
    })

    return (
        <Link to={`/company/?name=${encodeURIComponent(name)}`}>
            <Card 
                shadow='sm' 
                className={`font-medium min-h-20 leading-tight transition h-64 relative`}
            >
                <div className='px-6 pt-4 pb-3 bg-white'>
                    <p className='font-bold truncate tracking-tight'>{name}</p>
                    {meetingCount > 0 ? (
                        <Fragment>
                            {!officialCount && (
                                <p className='text-sm text-dark-grey mt-1 mb-0 leading-tight'>
                                    {meetingCount} meeting{meetingCount > 1 && `s`} with public office holders in the past twelve months
                                </p>
                            )}
                            {officialCount && (
                                <p className='text-sm text-dark-grey mt-1 mb-0 leading-tight'>
                                    {meetingCount} meeting{meetingCount > 1 && `s`} in the past year 
                                </p>
                            )}
                            {officialCount && (
                                <p className='text-sm text-dark-grey leading-tight'>
                                    with {contactCount} public office holder{contactCount > 1 && `s`}
                                </p>
                            )}
                        </Fragment>
                    ) : (
                        <p className='text-sm text-gray-500 mt-1 mb-0'>
                            No meetings in the past year
                        </p>
                    )}
                </div>
                <div className='flex gap-0.5 absolute bottom-3 left-6 z-20'>
                    {instChips}
                    {institutions.length > 3 ? <Chip label={`+${institutions.length - 3}`} /> : null}
                </div> 
                <ul className='text-sm border-t pt-3 px-6' 
                    ref={containerRef}
                >
                    {subjectBullets}
                </ul>
                {!isExpanded && isContentTaller && (
                    <div 
                        className='absolute bottom-0 left-0 z-10 text-center w-full'
                        style={{
                            background: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255, 90%), rgba(255,255,255, 100%)',
                            width: '100%',
                            height: 80
                        }}>
                        
                    </div>
                )}
            </Card>
        </Link>
    )
}

export default FirmCard;