import { gql } from "@apollo/client";

const GET_TOPIC = gql`
    query GetTopic($topic: String) {
        topic(topic: $topic) {
            name
            sentenceString
            topicData {
                firms {
                    name
                    meetingCount
                    subjects {
                        subject
                    }
                    institutions {
                        name
                        meetingCount
                    }
                }
                network {
                    nodes {
                        id
                        name
                        category
                    }
                    links {
                        source
                        target
                        weight
                        category
                    }
                }
            }
        }
    }
`

export default GET_TOPIC;