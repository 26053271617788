import React, { useEffect } from 'react';
import SignInModal from '../GlobalLayout/SignInModal/SignInModal';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const SignInPage = ({

}) => {

    const user = useSelector(state => state.auth.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate(`/timeline`)
        }
    }, [])

    const onClose = () => {
        navigate(`/timeline`)
    }

    return (
        <div className='bg-mid-grey'>
            <SignInModal 
                isOpen={true}
                onClose={onClose}
                showClose={false}
                showSignUp={false}
            />
        </div>
    )
}

export default SignInPage;