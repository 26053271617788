import React, { useEffect, useState } from 'react';
import InstitutionChip from '../ui/InstitutionChip/InstitutionChip';
import { getFormattedDate, getMeetingsByDate } from '../../utils/formatters';
import MeetingCard from '../TimelinePage/MeetingCard/MeetingCard';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroller';
import { Button, CircularProgress } from '@nextui-org/react';
import LoadingSpinner from '../ui/LoadingSpinner/LoadingSpinner';
import { ACCENT_INSTITUTION, MID_GREY } from '../../utils/colours';

const Timeline = ({
    timeline,
    showLobbyist,
    showConsultancy,
    showCompany,
    onFetchMore,
    totalTimeline,
    template = 'OFFICIALS' // OFFICIALS | CLIENTS | CARDS | PUBLIC_SERVANT
}) => {

    if (timeline.length === 0) {
        return (
            <div className='bg-offwhite rounded-lg text-center py-12'>
                <p className='text-gray-600'>No meetings in the past year</p>
            </div>
        )
    }

    const [loadingMore, setLoadingMore] = useState(false);

    const handleLoadMore = () => {
        setLoadingMore(true);
        onFetchMore();
    }

    useEffect(() => {
        if (loadingMore) {
            setLoadingMore(false)
        }
    }, [timeline])

    const dateSets = getMeetingsByDate(timeline);

    const dateBlocks = dateSets.map((dateEvents, i) => {

        const meetings = dateEvents.meetings.map((meeting, i) => {

            let blocks;

            if (template === 'CARDS') {
                return (
                    <MeetingCard key={i} {...meeting} />
                )
            }

            if (template === 'PUBLIC_SERVANT') {
                blocks = meeting.officials.map((official, m) => {
    
                    return (
                        <div key={m} className='flex my-0.5 items-start justify-between sm:justify-start gap-2 flex-grow'>
                            <Link to={`/public-servant/?name=${encodeURIComponent(official.name)}`}>
                                <p className='cursor-pointer'
                                    // onClick={openOfficialSidePeek}
                                >
                                    <span>{official.name}, </span>
                                    <span className='text-dark-grey'> {official.title}</span>
                                </p>
                            </Link>
                            <Link to={`/institution/?name=${encodeURIComponent(official.institution)}`}
                                 style={{ position: 'relative', top: 2 }}
                            >
                                <InstitutionChip 
                                    size='sm' 
                                    variant='flat' 
                                    key={i} 
                                    institution={official.institution} 
                                    
                                />
                            </Link>
                        </div>
                    )
                });

                return (
                    <div className='py-4 w-full border-b-2 border-accent-offwhite last:border-none' key={i}>
                        <div className="flex items-start gap-4">
                            <div style={{ position: 'relative', top: 2 }}>
                                <FontAwesomeIcon 
                                    icon={faCalendar} 
                                    className='fas' 
                                    color={MID_GREY}
                                    size='sm'
                                />
                            </div>
                            <div className='flex flex-col w-full'>
                                {showCompany && (
                                    <Link 
                                        className='font-bold mb-1'
                                        to={`/company/?name=${encodeURIComponent(meeting.clientNameEn)}`}
                                    >
                                        {meeting.clientNameEn}
                                    </Link>
                                )}
                                {blocks}
                                {showLobbyist && (
                                    <div className=''>
                                        {meeting.consultancy && meeting.consultancy !== 'null' && (
                                            <span>
                                                <Link 
                                                    className='mb-1'
                                                    to={`/lobbyist/?name=${encodeURIComponent(meeting.lobbyist)}`}
                                                >
                                                    {meeting.lobbyist}</Link>{showConsultancy && (
                                                    <span>, <Link to={`/consulting-firm/?name=${encodeURIComponent(meeting.consultancy)}`}
                                                        className='text-dark-grey'
                                                    >
                                                            {meeting.consultancy}
                                                        </Link>
                                                    </span>
                                                )}
                                            </span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    
                )

            }

            if (template === 'OFFICIALS') {
                blocks = meeting.officials.map((official, m) => {
                    return (
                        <div key={m} className='flex justify-between mb-1 flex-grow'>
                            <Link to={`/public-servant/?name=${official.name}`}>
                                <p className='OfficialLine cursor-pointer'
                                    // onClick={openOfficialSidePeek}
                                >
                                    <span>{official.name}, </span>
                                    <span className='text-dark-grey'> {official.title}</span>
                                </p>
                            </Link>
                            <Link to={`/institution/?name=${official.institution}`}
                               
                            >
                                <InstitutionChip 
                                    size='sm' 
                                    variant='flat' 
                                    key={i} 
                                    institution={official.institution} 
                                />
                            </Link>
                        </div>
                    )
                });
            }
    
            return (
                <div key={i} className="pt-4 py-3 flex items-start gap-4 w-full border-b-2 border-gray-200 last:border-none">
                    <div style={{ position: 'relative', top: 2 }}>
                        <FontAwesomeIcon 
                            icon={faCalendar} 
                            className='fas' 
                            color={ACCENT_INSTITUTION}
                            // size='lg' 
                            // color={CANADA_RED}
                        />
                    </div>
                    <div className='flex flex-col w-full'>
                        {blocks}
                    </div>
                </div>
            )
        })

        const formattedDate = getFormattedDate(dateEvents.date);

        return (
            <div key={i} className="mb-5">
                <p className="text-xs font-bold tracking-tight text-dark-grey -mb-2">{formattedDate}</p>
                {meetings}
            </div>
        )
    });

    const hasMore = totalTimeline > timeline.length;

    return (
        <div>
            {dateBlocks}
            {hasMore && (
                <div>
                    <Button
                        onClick={handleLoadMore}
                        className="font-medium px-8 w-2/3 sm:w-auto bg-mid-grey text-white rounded-xl sm:rounded-lg"
                        isLoading={loadingMore}
                        disableAnimation
                        disableRipple
                    >
                        {loadingMore ? 'Loading...' : 'Show more'}
                    </Button>
                </div>
            )}
        </div>
    )
}

export default Timeline;