import { useMutation } from '@apollo/client';
import { Button } from '@nextui-org/react';
import axios from 'axios';
import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { REQUEST_INVITE } from './mutations/requestInvite';

const GatePage = ({

}) => {

    const [email, setEmail] = useState('');
    const onChangeEmail = (e) => setEmail(e.target.value);

    const isValidEmail = isEmail(email);

    const [status, setStatus] = useState('');

    const [requestInvite, { loading, error, data }] = useMutation(REQUEST_INVITE);

    const onSubmit = (e) => {
        e.preventDefault();
        requestInvite({
            variables: {
                email: email
            },
            onCompleted: (data) => {
                if (data.requestInvite === true) {
                    setStatus('SUCCESS');
                }
            }
        })
    }

    return (
        <div>
            <div className='bg-mid-grey relative'>
                <div className='w-5/6 lg:w-3/5 mx-auto h-[21vh] pb-4 flex flex-col justify-end relative'>
                    <p className='text-white text-3xl font-bold tracking-tight'>
                        Lobby Report
                    </p>            
                </div>  
                <div className='absolute top-2 right-2'>
                    <Link to='/sign-in'>
                        <Button
                            size='sm'
                            variant='flat'
                            className='font-medium text-white'
                        >
                            Sign in
                        </Button>
                    </Link>
                </div>  
            </div>
            <div className='w-5/6 lg:w-3/5 mx-auto py-8 mb-24'>
                <h1 className='display-font border-b border-dotted border-mid-grey pb-5'>
                    Follow influence in Canada
                </h1>
                <div className='flex flex-col lg:flex-row gap-8 mt-6 flex-col-reverse'>
                    <div className='lg:w-1/2'>
                        <p className='font-bold leading-tight text-lg mb-2 max-w-72'>
                            Track the activity of key actors in Canadian public policy
                        </p>
                        <ul className='list-disc ml-4 text-lg marker:text-mid-grey'>
                            <li className='border-b border-dotted border-mid-grey py-1.5'>Government Institutions</li>
                            <li className='border-b border-dotted border-mid-grey py-1.5'>Public Office Holders</li>
                            <li className='border-b border-dotted border-mid-grey py-1.5'>Government Relations Teams</li>
                            <li className='border-b border-dotted border-mid-grey py-1.5'>Lobbyists</li>
                            <li className='py-1.5'>Companies & Non-Profits</li>
                        </ul>
                    </div>
                    <div className='lg:w-1/2 flex flex-col gap-3'>
                        {status === '' && (
                            <form 
                                className='flex flex-col gap-3'
                                onSubmit={onSubmit}
                            >
                                <input 
                                    className='border border-mid-grey px-4 py-2 shadow-md text-md'
                                    placeholder='Enter your work email' 
                                    type='email'
                                    value={email}
                                    onChange={onChangeEmail}
                                />
                                <Button
                                    className={!isValidEmail ? 'bg-mid-grey opacity-60 pointer-events-none text-white text-md cursor-not-allowed' : 'bg-mid-grey text-white text-md'}
                                    disableAnimation
                                    disableRipple
                                    disabled={!isValidEmail}
                                    type='submit'
                                    isLoading={loading}
                                    style={{
                                        borderRadius: 0
                                    }}
                                >
                                    Request access
                                </Button>
                            </form>
                        )}
                        {status === 'SUCCESS' && (
                            <div className='bg-offwhite px-8 py-6 border border-mid-grey leading-snug font-medium text-center'>
                                Thank you for your interest. A member of our team will reach out to you soon.
                            </div>
                        )}
                    </div>
                </div>
            </div>
            
            
        </div>
    )

}

export default GatePage;