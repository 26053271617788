import { gql } from "@apollo/client";

const LISTS_AND_TOPICS = gql`
    query Lists {
        lists {
            name
            slug
            firms
        }
    }
`

export default LISTS_AND_TOPICS;