import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons';
import { faBriefcase, faCalendar, faIndustry, faInstitution, faNewspaper, faQuestionCircle, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ModalContent } from '@nextui-org/react';
import React from 'react';
import { NavLink } from 'react-router-dom';

const MobileMenu = ({
    isOpen,
    onClose
}) => {

    const navLinkClasses = 'flex gap-2 items-center w-full px-0 pt-2 pb-2 rounded-lg';
    
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            classNames={{
                base: 'z-50'
            }}
        >
            <ModalContent>
                <div className='text-left py-4 pl-8'>
                    <NavLink to='/timeline'
                        className={navLinkClasses}
                        id='TimelineNavLink'
                        onClick={onClose}
                    >
                        <FontAwesomeIcon 
                            icon={faCalendar} 
                            className='fas text-mid-grey min-w-[20px]' 
                            size='sm'
                        />
                        <span className='text-lg font-medium'>
                            Recent Lobbying
                        </span>
                    </NavLink>
                    <NavLink to='/companies'
                        className={navLinkClasses}
                        onClick={onClose}
                    >
                        <FontAwesomeIcon 
                            icon={faIndustry} 
                            className='fas text-mid-grey min-w-[20px]' 
                            size='sm'
                        />
                        <span className='text-lg font-medium'>
                            Companies
                        </span> 
                    </NavLink>
                    <NavLink to='/lists'
                        className={navLinkClasses}
                        onClick={onClose}
                    >
                        <FontAwesomeIcon 
                            icon={faNewspaper} 
                            className='fas text-mid-grey min-w-[20px]' 
                            size='sm'
                        />
                        <span className='text-lg font-medium'>
                            Sectors & Lists
                        </span>
                    </NavLink>
                    <NavLink to='/consulting-firms'
                        className={navLinkClasses}
                        onClick={onClose}
                    >
                        <FontAwesomeIcon 
                            icon={faBriefcase} 
                            className='fas text-mid-grey min-w-[20px]'
                            size='sm' 
                        />
                        <span className='text-lg font-medium'>
                            Consulting Firms
                        </span>
                    </NavLink>
                    <NavLink to='/lobbyists'
                        className={navLinkClasses}
                        onClick={onClose}
                    >
                    <FontAwesomeIcon 
                            icon={faUser} 
                            className='fas text-mid-grey min-w-[20px]' 
                            size='sm'
                        />
                        <span className='text-lg font-medium'>
                            Lobbyists
                        </span>
                    </NavLink>
                    <NavLink to='/public-servants'
                        className={navLinkClasses}
                        onClick={onClose}
                    >
                    <FontAwesomeIcon 
                            icon={faCanadianMapleLeaf} 
                            className='fas text-mid-grey min-w-[20px]' 
                            size='sm'
                        />
                        <span className='text-lg font-medium'>
                            Government Officials
                        </span>
                    </NavLink>
                    <NavLink to='/institutions'
                        className={navLinkClasses}
                        onClick={onClose}
                    >
                        <FontAwesomeIcon 
                            icon={faInstitution} 
                            className='fas text-mid-grey min-w-[20px]' 
                            size='sm'
                        />
                        <span className='text-lg font-medium'>
                            Parts of Government
                        </span>
                    </NavLink>
                    <NavLink to='/about'
                        className={navLinkClasses}
                        onClick={onClose}
                    >
                        <FontAwesomeIcon 
                            icon={faQuestionCircle} 
                            className='fas text-mid-grey min-w-[20px]' 
                            size='sm'
                        />
                        <span className='text-lg font-medium'>
                            About this site
                        </span>
                    </NavLink>
                </div>
            </ModalContent>
        </Modal>
    )
}

export default MobileMenu;