import { gql } from "@apollo/client";

const LIST_TIMELINE = gql`
    query ListTimeline($offset: Int, $limit: Int, $companies: [String]) {
        totalTimeline(companies: $companies)
        timeline(offset: $offset, limit: $limit, companies: $companies) {
            _id
            date
            clientNameEn
            lobbyist
            consultancy
            officials {
                givenName
                familyName
                name
                title
                branch
                institution
            }
        }
    }
`

export default LIST_TIMELINE;