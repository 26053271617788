import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SignUpModal from '../GlobalLayout/SignUpModal/SignUpModal';

const SignUpPage = ({

}) => {

    const user = useSelector(state => state.auth.user);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate(`/timeline`)
        }
    }, [])

    const onClose = () => {
        navigate(`/timeline`)
    }

    return (
        <div>
            <SignUpModal 
                isOpen={true}
                onClose={onClose}
                showClose={false}
                showSignUp={false}
                minimal
            />
        </div>
    )
}

export default SignUpPage;