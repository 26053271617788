import { gql } from "@apollo/client";

const GET_LIST = gql`
    query GetList($list: String) {
        list(list: $list) {
            name
            companies {
                name
                meetingCount
                subjects {
                    subject
                }
                institutions {
                    name
                    meetingCount
                }
            }
            network {
                nodes {
                    id
                    name
                    category
                }
                links {
                    source
                    target
                    weight
                    category
                }
            }
        }
    }
`

export default GET_LIST;