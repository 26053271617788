import { useQuery } from '@apollo/client';
import React, { Fragment, useEffect, useState } from 'react';
import Footer from '../ui/Footer/Footer';
import SEO from '../ui/SEO/SEO';
import IndexHeader from '../ui/IndexHeader/IndexHeader';
import { MAIN_BUTTON_CLASSES } from '../../utils/formatters';
import LISTS_AND_TOPICS from './queries/listsAndTopics';
import ProfileLoading from '../ui/ProfileLoading/ProfileLoading';
import { Link } from 'react-router-dom';
import { Card } from '@nextui-org/react';

const ListIndex = ({

}) => {

    const { loading, error, data, fetchMore } = useQuery(LISTS_AND_TOPICS, {

    });

    // const [loadingMore, setLoadingMore] = useState(false);

    // const onFetchMore = () => {
    //     setLoadingMore(true);
    //     fetchMore({
    //         variables: {
    //             offset: data.lobbyists.length
    //         }
    //     })
    // }

    // useEffect(() => {
    //     if (loadingMore) {
    //         setLoadingMore(false);
    //     }
    // }, [data])

    if (loading && !data) {
        return (
            <Fragment>
                <ProfileLoading />
            </Fragment>
        )
    } 

    const lists = data.lists.map((list, i) => {
        
        const companyChips = list.firms.map((company, i) => {
            return (
                <div className='bg-offwhite text-sm rounded-lg inline-block mb-1 mr-1 px-2.5 py-1 border-2 border-white leading-tight' key={i}>
                    {company}
                </div>
            )
        })
            
        return (
            <Link to={`/list/${list.slug}`} key={i}>
                <Card className='px-7 py-4 hover:bg-offwhite transition-all' shadow='sm'>
                    <p className='text-2xl font-medium tracking-tight'>
                        {list.name}
                    </p>
                    <div className='mt-2'>
                        {companyChips}
                    </div>
                </Card>
            </Link>
        )
    })

    // const hasMore = data.totalLobbyists > data.lobbyists.length;

    return (
        <div className='mt-0 mb-16 mx-4 sm:mx-0'>
            <IndexHeader
                heading={'Sectors & Lists'}
                // subheading={'Government relations professionals in Canada'}
            />
            <div className='flex flex-col gap-3'>
                {lists}
            </div>
            <Footer />
        </div>
    )
}

export default ListIndex;
