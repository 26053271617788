import React, { useCallback, useEffect, useState } from "react";
import { Button, Dropdown, DropdownItem, DropdownMenu, Input } from "@nextui-org/react";
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useGoogleLogin } from '@react-oauth/google';
import _ from 'lodash';
import GOOGLE_LOGIN from "./mutations/googleLogin";
import { setUser } from "../../store/slices/authSlice";
import { setLocalToken } from "../../utils/authUtils";
import { Link, NavLink, useNavigate } from "react-router-dom";
import GoogleLogo from "../ui/icons/GoogleLogo/GoogleLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import './SignInGate.css';
import StackedAreaChart from "../charts/StackedAreaChart/StackedAreaChart";
import TIMELINE_PERCENTAGE_CHART from "./queries/companyScatterplot";
import ScatterPlot from "../charts/Scatterplot/Scatterplot";
import COMPANY_SCATTERPLOT from "./queries/companyScatterplot";
import PROFILE_EXAMPLES from "./queries/profileExamples";
import InstitutionName from "../ui/InstitutionName/InstitutionName";
import './SignInGate.css';
import HomeScatterplot from "./HomeScatterplot/HomeScatterplot";
import CompanyCard from "./CompanyCard/CompanyCard";
import GroupCard from "./GroupCard/GroupCard";
import PublicOfficeHolderCard from "./PublicOfficeHolderCard/PublicOfficeHolderCard";
import SearchResults from './../GlobalLayout/SideMenu/Search/SearchResults/SearchResults';
import TypeIcon from "../ui/TypeIcon/TypeIcon";
import SEARCH from "../GlobalLayout/SideMenu/Search/queries/search";
import { useMediaQuery } from "@uidotdev/usehooks";
import Footer from "../ui/Footer/Footer";
import GatePage from "../GatePage/GatePage";

const exampleProfiles = {
    company1: "Amazon Web Services Canada, Inc.",
    company2: "Armatec Survivability",
    consulting1: "Sandstone Group",
    institution1: "Natural Resources Canada (NRCan)",
    institution2: "Prime Minister's Office (PMO)",
    publicServant: "Ben Chin"
}

const exampleChips = [
    {
        name: 'Amazon',
        type: 'company',
        fullName: 'Amazon Corporate LLC'    
    },
    {
        name: 'Finance Canada',
        type: 'institution',
        fullName: 'Finance Canada (FIN)'
    },
    {
        name: 'Dow Chemical',
        type: 'company',
        hideSmall: true,
        fullName: 'Dow Chemical Canada ULC'
    },
    {
        name: `Privy Council Office`,
        type: 'institution',
        hideSmall: true,
        fullName: 'Privy Council Office (PCO)'
    },
    {
        name: 'Chrystia Freeland',
        type: 'public-servant',
        fullName: 'Chrystia Freeland'
    },
    {
        name: 'Crestview Strategy',
        type: 'consulting-firm',
        fullName: 'Crestview Strategy',
    },
    // {
    //     name: 'Raytheon',
    //     type: 'company'
    // },

    // {
    //     name: 'Dow Chemical',
    //     type: 'company'
    // },
   
    
    // {
    //     name: 'Capital Hill Group',
    //     type: 'consulting-firm'
    // },
    // {
    //     name: 'StrategyCorp',
    //     type: 'consulting-firm'
    // },
    // {
    //     name: 'Marc Desmarais',
    //     type: 'lobbyist'
    // },
    // {
    //     name: 'Dan Pfeffer',
    //     type: 'lobbyist'
    // },
    // {
    //     name: 'David Pratt',
    //     type: 'lobbyist'
    // },
   
    // {
    //     name: 'Natural Resources Canada',
    //     type: 'institution'
    // },
    // {
    //     name: `Prime Minister's Office`,
    //     type: 'institution'
    // },
    // {
    //     name: `Health Canada`,
    //     type: 'institution'
    // },
    // {
    //     name: `Global Affairs Canada`,
    //     type: 'institution'
    // },
   
]

const SignInGate = ({

}) => {
 
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector(state => state.auth.user);

    const [query, setQuery] = useState('');
    const [prevQuery, setPrevQuery] = useState('');
    const [hasMoreResults, setHasMoreResults] = useState(true); 
    const [searchIsFocused, setSearchIsFocused] = useState(false);
    const [resultsAreHovered, setResultsHovered] = useState(false);
    const onFocusSearch = () => setSearchIsFocused(true);
    const onBlurSearch = () => setSearchIsFocused(false);
    const onHoverResults = () => setResultsHovered(true);
    const onMouseoutResults = () => setResultsHovered(false);

    const [ onSearch, { loading, error, data, previousData, fetchMore } ] = useLazyQuery(SEARCH, {
        variables: {
            query: query,
            offset: 0,
            limit: 10
        }
    });

    const handleSearch = (query) => {
        setPrevQuery(query);
        onSearch({
            variables: {
                query: query,
                offset: 0,
                limit: 10
            }
        })
    }

    const [loadingMore, setLoadingMore] = useState(false);

    const onFetchMore = () => {
        setLoadingMore(true);
        fetchMore({
            variables: {
                offset: data.search.results.length
            }
        })
    }

    useEffect(() => {
        if (data && previousData) {
            setHasMoreResults(data.search.length !== previousData.search.length);
        }
        if (loadingMore) {
            setLoadingMore(false);
        }
    }, [data])

    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

    const debouncedSearch = useCallback(_.debounce(handleSearch, 300), []); 

    const onChangeQuery = (e) => {
        setQuery(e.target.value);
        if (e.target.value.length >= 3) {
            debouncedSearch(query)
        }
    }

    useEffect(() => {
        if (query.trim() !== prevQuery.trim()) {
            debouncedSearch(query);
        }
    }, [data]);

    useEffect(() => {
        if (user) {
            navigate('/timeline')
        }
    }, [user]);

    const chipSet = exampleChips.map((chip, i) => {

        let classes = [
            'flex',
            'w-auto',
            'px-4',
            'py-2',
            'rounded-xl',
            'border',
            'border-gray-200',
            'hover:border-offwhite',
            'hover:bg-offwhite',
            'transition-all',
            'shadow-sm',
            'shadow-lg'
        ];

        if (chip.hideSmall === true) {
            classes.push('hidden', 'lg:block')
        }

        return (
            <Link 
                to={`/${chip.type}/?name=${encodeURIComponent(chip.fullName)}`}
                key={i} className={classes.join(' ')}
            >
                <div className="flex gap-2 items-center">
                    <TypeIcon type={chip.type} />
                    {chip.name}
                </div>
            </Link>
        )
    })

    return <GatePage />

    return (
        <div className="">
            <div className="flex flex-col -mt-10 h-screen justify-center items-center">
                <div className="">
                    <div className="mx-auto text-left sm:text-center">
                        <p className="font-medium tracking-tight text-4xl sm:text-5xl mb-8">
                            Follow influence in Canada
                        </p>
                        <div className="relative">
                            <input
                                className="border-offwhite hover:border-offwhite-hover focus:border-offwhite-hover border-3 px-6 py-4 rounded-lg w-full placeholder-gray-600"
                                placeholder={isSmallDevice ? "Search the database" : "Search people, companies, topics or parts of government..."}
                                value={query}
                                onChange={onChangeQuery}
                                onFocus={onFocusSearch}
                                onBlur={onBlurSearch}
                            />
                            {prevQuery && (searchIsFocused || resultsAreHovered) && previousData && (
                                <div className="bg-white absolute border-offwhite focus:border-offwhite-hover border shadow-lg mt-1 rounded-lg text-left max-h-72 w-full"
                                    onMouseEnter={onHoverResults}
                                    onMouseLeave={onMouseoutResults}
                                >
                                    <SearchResults
                                        data={data ? data : previousData ? previousData : null}
                                        // handleClose={handleClose}
                                        onFetchMore={onFetchMore}
                                        loadingMore={loadingMore}
                                        hasMoreResults={hasMoreResults}
                                        showMoreBtn={false}
                                        maxHeight={64}
                                        condensed
                                        scroll
                                    />
                                </div>
                            )}
                        </div>
                        <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-1">
                            {chipSet}
                        </div>
                        <div className="text-center mx-8 md:mx-auto mt-8 pt-8">
                            <p className="font-medium text-xs">
                                Made in Toronto by <a 
                                    href='https://x.com/tuckermclachlan' 
                                    target='_blank'
                                >
                                    @tuckermclachlan
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="-mt-16 mb-8">
                <Footer align="text-center" home />
            </div>  
        </div>
    )
}

export default SignInGate