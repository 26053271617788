import React from 'react';
import { Card } from '@nextui-org/react';
import { Link, useNavigate } from 'react-router-dom';
import SearchResultCard from '../SearchResultCard/SearchResultCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';

const ConsultingFirmSearchResult = ({
    name,
    meetingCount,
    handleClose,
    style,
    condensed
}) => {

    const navigate = useNavigate();

    const handleClick = (e) => {
        e.preventDefault();
        navigate(`/consulting-firm/?name=${encodeURIComponent(name)}`)
        handleClose();
    }

    return (
        <Link 
            to={`/consulting-firm/?name=${encodeURIComponent(name)}`} 
            onClick={handleClick}
            style={style}
        >
            <SearchResultCard 
                style={style} 
                condensed={condensed}
            >
                <div className='flex gap-4'>
                    <div>
                        <FontAwesomeIcon
                            icon={faBriefcase}
                            className='text-mid-grey'
                            size={condensed ? 'sm' : null}
                        />
                    </div>
                    <div>
                        <p>{name}</p>
                        {!condensed && (
                            <div>
                                <p className='text-gray-500 text-sm'>
                                    {meetingCount} meeting{meetingCount !== 1 && 's'} in the past year
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </SearchResultCard>
        </Link>
    )

}

export default ConsultingFirmSearchResult;