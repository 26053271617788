import React, { Fragment } from 'react';
import PageHeading from '../PageHeading/PageHeading';

const IndexHeader = ({
    heading,
    subheading
}) => {

    return (
        <Fragment>
            <PageHeading text={heading} />
            <h2 className='text-lg font-medium text-dark-grey tracking-tight leading-tight mb-8'>
                {subheading}
            </h2>
        </Fragment>
    )
}

export default IndexHeader;