import React from 'react';

const PageHeading = ({
    text
}) => {
    return (
        <h1 className='display-font mb-2 text-4xl md:text-5xl'>
            {text}
        </h1>
    )
}

export default PageHeading;