import React from 'react';
import InstitutionChip from '../../ui/InstitutionChip/InstitutionChip';
import { Link } from 'react-router-dom';
import { Card } from '@nextui-org/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MID_GREY } from '../../../utils/colours';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const TopicFirmBlock = ({
    name,
    subjects,
    institutions,
    meetingCount
}) => {

    const subjectList = subjects.map((subject,i) => {
        return (
            <p key={i} className='mb-3 pb-4 border-b border-gray-200 last:mb-0 last:border-none text-lg'>
                {subject.subject}
            </p>
        )
    });

    const institutionTags = institutions.filter((inst, i) => i < 3).map((inst, i) => {
        return <InstitutionChip institution={inst.name} key={i}  />
    })

    return (
        <Link to={`/company/?name=${name}`}>
            <Card
                className='px-7 py-4 my-3 hover:bg-offwhite transition-all font-normal' 
                shadow='sm'
            >
                <div className='flex flex-col sm:flex-row sm:items-center gap-2 justify-between'>
                    <span className='font-bold leading-tight'>
                        {name}
                    </span>
                    <div>
                        <div className='flex gap-0.5 items-center'>
                            {/* <div className='mr-1'>
                                <FontAwesomeIcon
                                    icon={faArrowRight}
                                    color={MID_GREY}
                                    opacity={0.7}
                                />
                            </div> */}
                            {/* <div className='flex gap-0.5'> */}
                                {institutionTags}
                            {/* </div> */}
                            {institutions.length > 3 && (
                                <span className='text-gray-800 font-medium bg-mid-grey text-white rounded-xl text-xs tracking-tight px-2 py-0.5 inline-block'>
                                    +{institutions.length - 3}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
       
                <div className=''>
                    {subjectList}
                </div>
                <div>
                    <p className='text-xs font-medium text-dark-grey'>
                        {meetingCount} meeting{meetingCount !== 1 ? 's' : ''} with Canadian government officials in the past year
                    </p>
                </div>    
            </Card>
        </Link> 
    )
}

export default TopicFirmBlock;