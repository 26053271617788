import { gql } from "@apollo/client";

const GET_TOPIC_NAME = gql`
    query GetTopic($topic: String) {
        topic(topic: $topic) {
            name
            sentenceString
        }
    }
`

export default GET_TOPIC_NAME;