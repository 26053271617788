import React, { Fragment, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ProfileHeadline from '../ui/ProfileHeadline/ProfileHeadline';
import PageHeading from '../ui/PageHeading/PageHeading';
import { useLazyQuery, useQuery } from '@apollo/client';
import GET_TOPIC from './queries/topic';
import ProfileLoading from '../ui/ProfileLoading/ProfileLoading';
import { Card, Spinner } from '@nextui-org/react';
import InstitutionChip from '../ui/InstitutionChip/InstitutionChip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { MID_GREY } from '../../utils/colours';
import PageSummary from '../ui/PageSummary/PageSummary';
import { useDispatch } from 'react-redux';
import { setBreadcrumb } from '../../store/slices/uiSlice';
import TopicNetworkDiagram from './TopicNetworkDiagram/TopicNetworkDiagram';
import GET_TOPIC_NAME from './queries/topicPreQuery';
import TopicFirmBlock from './TopicFirmBlock/TopicFirmBlock';
import NetworkLoadingGraphic from './NetworkLoadingGraphic/NetworkLoadingGraphic';

const TopicProfile = ({

}) => {

    const dispatch = useDispatch();
    const { topic } = useParams();

    const { loading, error, data: topicNameData } = useQuery(GET_TOPIC_NAME, {
        variables: {
            topic: topic
        }
    });

    const [loadTopic, { loading: loadingTopicData, error: topicDataError, data: topicData}] = useLazyQuery(GET_TOPIC, {
        variables: {
            topic: topic
        }
    })

    useEffect(() => {
        if (topicNameData) {
            dispatch(setBreadcrumb(topicNameData.topic.name));
            loadTopic();
        }
    }, [topicNameData])

    if (loading && !topicNameData) {
        return (
            <Fragment>
                <ProfileLoading />
            </Fragment>
        )
    }    

    let relevantFirms, subjectBlocks;

    if (topicData) {

        relevantFirms = topicData.topic.topicData.firms.filter((firm, i) => i < 20).map(firm => firm.name);

        subjectBlocks = topicData.topic.topicData.firms.map((topicFirm, i) => {
            return <TopicFirmBlock key={i} {...topicFirm} />;
        });
    }
   
    return (
        <div>
            <div className="mx-4 sm:mx-0">
                <PageHeading text={topicNameData.topic.name} />
            </div>
            <div className="ml-2 mr-4 sm:mr-0">
                <PageSummary 
                    text={topicData ? `There are currently ${topicData.topic.topicData.firms.length} organizations lobbying the Government of Canada about ${topicData.topic.sentenceString}` : 'There are currently... '}
                />
            </div>
            <div>
                <div className='shadow rounded-2xl mt-4 hidden sm:block'>
                    <div className='bg-offwhite rounded-t-2xl border-b border-gray-200 px-4 py-3'>
                        <div className='text-sm font-semibold flex gap-2 items-center'>
                            {!topicData && <Spinner size='sm' className='opacity-50' />}
                            Notable organizations: {topicNameData.topic.name}
                        </div>
                        {topicData && (
                            <div className="mt-1 flex flex-wrap gap-4 justify-start">
                                <div className="flex items-center gap-1 font-medium">
                                    <div className="w-4 h-4 rounded-full bg-mid-grey" />
                                    <span className="text-xs">Company</span>
                                </div>
                                <div className="flex items-center gap-1 font-medium">
                                    <div className="w-4 h-4 rounded-full bg-[#f87171]" />
                                    <span className="text-xs">Government Institution</span>
                                </div>
                            </div>
                        )}
                    </div>
                    
                    <div className='relative'>
                        {!topicData && (
                            <div className='min-h-[500px] flex justify-center items-center'>
                                <p className='text-mid-grey font-medium'>Loading relationships</p>
                            </div>
                        )}
                        <div className='min-h-[500px] absolute flex justify-center items-center'
                            style={{ 
                                background: '#fff',
                                left: 0,
                                top: 0,
                                width: '100%',
                                height: 500,
                                zIndex: 9999,
                                opacity: topicData ? 0 : 1,
                                pointerEvents: topicData ? 'none' : 'all',
                                transition: 'opacity 0.3s'
                            }}
                        >
                            <p className='text-mid-grey font-medium'>Loading relationships</p>
                        </div>
                        <div className='min-h-[500px] flex justify-center items-center'
                        >
                            {topicData && (
                                <TopicNetworkDiagram 
                                    data={topicData.topic.topicData.network}
                                />
                            )} 
                        </div>
                    </div>
      
                </div>
                
            </div>  
            <div className='sm:mt-8 mx-4 sm:mx-0'> 
                {subjectBlocks}
            </div>
        </div>
    )
}

export default TopicProfile;