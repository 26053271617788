import { faBriefcase, faIndustry, faPerson } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, Chip } from '@nextui-org/react';
import React from 'react';
import { onlyUnique } from '../../../utils/onlyUnique';
import { CANADA_RED, DARK_GRAY_BLUE } from '../../../utils/colours';
import { faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons';
import InstitutionChip from '../../ui/InstitutionChip/InstitutionChip';
import './MeetingCard.css';
import { useDispatch } from 'react-redux';
import { setSidePeek } from '../../../store/slices/uiSlice';

const MeetingCard = ({
    officials,
    clientNameEn
}) => {

    const dispatch = useDispatch();
    
    const openClientSidePeek = () => {
        dispatch(setSidePeek({
            open: true,
            template: 'FIRM',
            profileID: clientNameEn
        }));
    }

    let publicServants = officials.map((official, i) => {

        const openOfficialSidePeek = () => {
            dispatch(setSidePeek({
                open: true,
                template: 'OFFICIAL',
                profileID: official.name
            }));
        }

        return (
            <div key={i} className='flex justify-between mb-1'>
                <p className='OfficialLine cursor-pointer hover:underline'
                    onClick={openOfficialSidePeek}
                >
                    <span>{official.name}, </span>
                    <span className='text-gray-500'> {official.title}</span>
                </p>
                <InstitutionChip size='sm' variant='flat' key={i} institution={official.institution} />
            </div>
        )
    });

    const institutions = officials.map(p => p.institution).filter(onlyUnique);
    const institutionChips = institutions.map((inst, i) => {
        return (
            <InstitutionChip size='sm' variant='flat' key={i} institution={inst} />
        )
    })

    // const subjectChips = meeting.subjects.map(subject => {
    //     return (
    //         <Chip size='sm' variant='flat'>{subject}</Chip>
    //     )
    // })

    return (
        <Card shadow='sm' className='mb-3 w-full' > 
            <CardBody className='px-6 py-4'>
                <p className='font-bold flex gap-4 items-center'>
                    <FontAwesomeIcon 
                        icon={faIndustry} 
                        className='fas muted-icon' 
                        size='sm' 
                        color={DARK_GRAY_BLUE}
                    />
                    <span className='ml-0.5 cursor-pointer hover:underline' onClick={openClientSidePeek}>
                        {clientNameEn}
                    </span>
                </p>
                <div className='flex gap-4 mt-1'>
                    <div className='flex-grow flex gap-4 items-center'>
                        <FontAwesomeIcon 
                            icon={faCanadianMapleLeaf} 
                            className='fas' 
                            // size='lg' 
                            color={DARK_GRAY_BLUE}
                        />
                        <div className='flex-grow OfficialsContainer'>
                            {publicServants}
                        </div>
                    </div>
                    {/* <div className='flex gap-1'>{institutionChips}</div> */}
                </div>
                {/* <div className='mt-3 ml-7'>
                    {subjectChips}
                </div> */}
            </CardBody>
        </Card>
    )
}

export default MeetingCard;