import React from 'react';

const PageSummary = ({
    text
}) => {
    return (
        <div className='pl-2'>
            <div className="bg-offwhite mt-4 py-4 sm:py-3 px-4 sm:rounded-xl sm:-ml-5 rounded-xl">
                <p className="text-lg leading-tight sm:w-3/4 font-medium flex flex-col md:block ">
                    {text}
                </p>
            </div>
        </div>
    )
}

export default PageSummary;