import React from 'react';

const ChartTooltip = ({ 
    position,
    children,
    visible
}) => {

    let leftPos = position.x + 10;
    let topPos = position.y + 10;
    if (leftPos + 800 > window.innerWidth) {
        leftPos = position.x - 330;
    }

    if (window.innerWidth < 800) {
        leftPos = position.x - 200;
        topPos = position.y + 30
    }

    if (window.innerWidth < 800 && position.x - 200 < 0) {
        leftPos = position.x - 50;
        topPos = position.y + 30
    }

    if (window.innerWidth < 800 && position.x + 500 > 800) {
        leftPos = position.x - 300;
        topPos = position.y + 30
    }

    let classes = `ChartTooltip shadow-lg rounded-lg absolute px-4 pt-3 pb-3 border border-gray-200 bg-white z-50 w-80 transition-opacity`;
    if (!visible) {
        classes = `ChartTooltip shadow-lg rounded-lg absolute px-4 pt-3 pb-3 border border-gray-200 bg-white z-50 w-80 transition-opacity opacity-0`;
    }

    return (
        <div 
            className={classes}
            style={{ 
                left: leftPos, top: topPos
            }}
        >
            {children}
        </div>
    );
};

export default ChartTooltip;
